import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../components/grid";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../utils/const/estilos-const";
import ModalConfirmar from "../../components/modal-confirmar";
import "../../style/style-cargando.css";
import {
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../utils/const/int-const";
import { TRUE } from "../../utils/const/bool-const";
import cargandoCard from "../../components/cargando-card";
import sinAccesoCard from "../../components/sin-acceso-card";
import { Settings } from "feather-icons-react/build/IconComponents";
import { deshabilitarArea, obtenerListaArea } from "../../api/area";
import { deshabilitarRespuestaChecklist, obtenerChecklistRealizadoPorUsuarioMovil } from "../../api/checklist";
import { sharedNextPage } from "../../utils/shared-functions";

const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));
let accesoBotonNuevo = 'none';


export default function RespuestasChecklist() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "CODIGO", visible: true, },
    { key: "idChecklist", title: "ID CHECKLIST", visible: false, filter: true, editLabel: true, },
    { key: "nombreChecklist", title: "NOMBRE CHECKLIST", visible: true, filter: true, },
    { key: "codigoVehiculo", title: "CODIGO VEHICULO", visible: false, filter: true, },
    { key: "valueVehiculo", title: "VALUE VEHICULO", visible: false, filter: true, },
    { key: "labelVehiculo", title: "VEHICULO", visible: true, filter: true, },
    { key: "odometroActual", title: "ODOMETRO ACTUAL", visible: true, filter: true, },
    { key: "odometroProximo", title: "ODOMETRO PROXIMO", visible: true, filter: true, },
    { key: "fechaActualChecklist", title: "FECHA ACTUAL CHECKLIST", visible: true, filter: true, },
    { key: "fechaProximoChecklist", title: "FECHA PROXIMO CHECKLIST", visible: true, filter: true, },
    { key: "codigoConductor", title: "CODIGO CONDUCTOR", visible: false, filter: true, },
    { key: "valueConductor", title: "VALUE CONDUCTOR", visible: false, filter: true, },
    { key: "labelConductor", title: "CONDUCTOR", visible: true, filter: true, },
    { key: "idArea", title: "ID AREA", visible: false, filter: true, editLabel: true, },
    { key: "nombreArea", title: "NOMBRE AREA", visible: true, filter: true, },
    { key: "idCiudad", title: "ID CIUDAD", visible: false, filter: true, editLabel: true, },
    { key: "nombreCiudad", title: "CIUDAD", visible: true, filter: true, },
    { key: "idTipoChecklist", title: "ID TIPO CHECKLIST", visible: false, filter: true, editLabel: true, },
    { key: "tipoChecklist", title: "TIPO CHECKLIST", visible: true, filter: true, },
    { key: "idTipoVehiculo", title: "ID TIPO VEHICULO", visible: false, filter: true, editLabel: true, },
    { key: "tipoVehiculo", title: "TIPO VEHICULO", visible: true, filter: true, },
    { key: "idMarcaVehiculo", title: "ID MARCA VEHICULO", visible: false, filter: true, editLabel: true, },
    { key: "marcaVehiculo", title: "MARCA VEHICULO", visible: true, filter: true, },
    { key: "idTipoCarroceria", title: "ID TIPO CARROCERIA", visible: false, filter: true, editLabel: true, },
    { key: "tipoCarroceria", title: "TIPO CARROCERIA", visible: true, filter: true, },
    { key: "idTipoCombustible", title: "ID TIPO COMBUSTIBLE", visible: false, filter: true, editLabel: true, },
    { key: "tipoCombustible", title: "TIPO COMBUSTIBLE", visible: true, filter: true, },
    { key: "fechaProceso", title: "FECHA PROCESO", visible: true, filter: true },
  ];
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaElementos, setListaElementos] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [elementoSelected, setElementoSelected] = useState(null);
  // const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(true);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      /*let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
      obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      */
      let permiso = null;
      let lista = [];

      // if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      accesoBotonNuevo = '';
      setTieneAccesoAlModulo(true);
      lista = await obtenerChecklistRealizadoPorUsuarioMovil();
      // }
      // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      //   if (permiso.accesoAlModulo === TRUE) {
      //     setTieneAccesoAlModulo(true);
      //     lista = await obtenerChecklistRealizadoPorUsuarioMovil();
      //   }
      // }

      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      // console.log('agregarAccionesALista');
      // console.log(lista);
      if (lista.length === 0) {
        setListaElementos(lista);
      }
      if (lista.length > 0) {
        const objVer = {
          label: "Ver",
          color: "green",
          onClick: (param) => verElementoSelected(param),
        };
        const objDeshabilitar = {
          label: "Deshabilitar",
          color: "red",
          onClick: (param) => cambiarEstado(param),
        };
        // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        // if (permiso.nuevo === TRUE) {
        accesoBotonNuevo = '';
        // }
        // }
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objVer);
            acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            // if (permiso.modificar === TRUE) {
            // }
            // if (permiso.eliminarDeshabilitar === TRUE) {
            acciones.push(objVer);
            acciones.push(objDeshabilitar);
            // }
          }
          lista[i].acciones = acciones;
        }
        setListaElementos(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const verElementoSelected = (data) => {
    try {
      // console.log("editarElementoSelected");
      // console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "DETALLE RESPUESTA CHECKLIST";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      sharedNextPage("detalle-respuestas-checklist", obj, history);
    } catch (err) {
      console.log("editarElementoSelected err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setElementoSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleAtrasClick = () => {
    history.goBack();
  }

  const reset = async () => {
    setCargandoPage(true);
    iniciar();
  }

  const handleCancelModal = () => {
    setModalConfirmarDeshabilitarVisible(false);
  }

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (elementoSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = elementoSelected.id;
    if (elementoSelected.id > 0) {
      // console.log('crear correo');
      let deshabilitado = await deshabilitarRespuestaChecklist(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (tieneAccesoAlModulo === false) {
        return sinAccesoCard();
      }
      return (
        <div>
          <div className="pt-4 px-4 flex justify-between">
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              LISTA RESPUESTAS CHECKLIST
            </label>
            <div className="flex justify-center">
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                <Settings />
              </button>
            </div>
          </div>
          <Grid rows={listaElementos} headers={headers} />
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={handleCancelModal}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar Respuesta Checklist?"
          />
        </div>
      );
    } catch (err) {
      console.log('ListarArea render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
