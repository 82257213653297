import { useState, useEffect } from "react";
import cargandoCard from "../../components/cargando-card";
import sinAccesoCard from "../../components/sin-acceso-card";
import ModalConfirmar from "../../components/modal-confirmar";
import Grid from "../../components/grid";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../utils/const/estilos-const";
import { useHistory } from "react-router-dom";
import { Settings } from "feather-icons-react/build/IconComponents";
import { INT_TIPO_USUARIO_ADMINISTRADOR_WEB } from "../../utils/const/int-const";
import { crearNuevoChecklist, deshabilitarChecklist, obtenerListaChecklist } from "../../api/checklist";
import { TRUE } from "../../utils/const/bool-const";
import { sharedNextPage } from "../../utils/shared-functions";
import ModalCrearChecklist from "../../components/modal-crear-checklist";

const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));
let accesoBotonNuevo = 'none';
export default function ChecklistScreen() {

    const headers = [
        { key: "acciones", title: "ACCIONES" },
        { key: "id", title: "ID", visible: false, },
        { key: "nombre", title: "NOMBRE", visible: true, filter: true, editLabel: true, },
        { key: "idTipoChecklist", title: "idTipoChecklist", visible: false, filter: false, editLabel: false, },
        { key: "tipoChecklist", title: "TIPO CHECKLIST", visible: true, filter: true, editLabel: true, },
        { key: "manejoDefensivo", title: "MANEJO DEFENSIVO", visible: false, filter: false, editLabel: false, },
        { key: "manejoDefensivoLabel", title: "MANEJO DEFENSIVO", visible: true, filter: false, editLabel: false, },
        // { key: "area", title: "AREA", visible: true, filter: true, editLabel: true, },
        // { key: "idCiudad", title: "idCiudad", visible: false, filter: false, editLabel: false, },
        // { key: "ciudad", title: "CIUDAD", visible: true, filter: true, editLabel: true, },
        // { key: "idTipoVehiculo", title: "idTipoVehiculo", visible: false, filter: false, editLabel: false, },
        // { key: "tipoVehiculo", title: "TIPO VEHICULO", visible: true, filter: true, editLabel: true, },
        // { key: "idMarcaVehiculo", title: "idMarcaVehiculo", visible: false, filter: false, editLabel: false, },
        // { key: "marcaVehiculo", title: "MARCA VEHICULO", visible: true, filter: true, editLabel: true, },
        // { key: "idTipoCombustible", title: "idTipoCombustible", visible: false, filter: false, editLabel: false, },
        // { key: "tipoCombustible", title: "TIPO COMBUSTIBLE", visible: true, filter: true, editLabel: true, },
        { key: "nota", title: "NOTA", visible: true, filter: true, editLabel: true, },
        { key: "fechaProceso", title: "FECHA PROCESO", visible: true, filter: true },
    ];

    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(false);
    const [listaElementos, setListaElementos] = useState([]);
    const [elementoSelected, setElementoSelected] = useState(null);
    const [elemento, setElemento] = useState({});
    const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
    // const [permisos, setPermisos] = useState(null);
    const [modalCrearChecklistVisible, setModalCrearChecklistVisible] = useState(false);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            /*let obj = {};
            obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
            obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
            obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA;
            let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
            let permiso = obtenerPermisos[0];
            setPermisos(permiso);
            */
            let permiso = null;
            let lista = [];

            // if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            accesoBotonNuevo = '';
            setTieneAccesoAlModulo(true);
            lista = await obtenerListaChecklist();
            // }
            // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            //     if (permiso.accesoAlModulo === TRUE) {
            //         setTieneAccesoAlModulo(true);
            //         lista = await obtenerListaChecklist();
            //     }
            // }

            agregarAccionesALista(lista, permiso);
            setCargandoPage(false);
        } catch (err) {
            setTieneAccesoAlModulo(false);
            console.log('iniciar err');
            console.log(err);
        }
    }

    const agregarAccionesALista = (lista, permiso) => {
        try {
            // console.log('agregarAccionesALista');
            // console.log(lista);
            if (lista.length === 0) {
                setListaElementos(lista);
            }
            if (lista.length > 0) {
                const objModificar = {
                    label: "Modificar",
                    color: "green",
                    onClick: (param) => editarElementoSelected(param),
                };
                const objDeshabilitar = {
                    label: "Deshabilitar",
                    color: "red",
                    onClick: (param) => cambiarEstado(param),
                };
                const objFormulario = {
                    label: "Formulario",
                    color: "blue",
                    onClick: (param) => goToFormularioChecklist(param),
                };
                // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                // if (permiso.nuevo === TRUE) {
                accesoBotonNuevo = '';
                // }
                // }
                for (let i = 0; i < lista.length; i++) {
                    let acciones = [];
                    if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                        acciones.push(objFormulario);
                        acciones.push(objModificar);
                        acciones.push(objDeshabilitar);
                    }
                    if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
                        // if (permiso.modificar === TRUE) {
                        acciones.push(objFormulario);
                        acciones.push(objModificar);
                        // }
                        // if (permiso.eliminarDeshabilitar === TRUE) {
                        acciones.push(objDeshabilitar);
                        // }
                    }
                    lista[i].acciones = acciones;
                }
                setListaElementos(lista);
            }
        } catch (err) {
            console.log('agregarAccionesALista err');
            console.log(err);
        }
    }

    const editarElementoSelected = (data) => {
        try {
            // console.log("editarElementoSelected");
            // console.log(data);
            if (data.acciones !== undefined) {
                delete data.acciones;
            }
            let objData = {};
            objData.id = data.id;
            objData.manejoDefensivo = data.manejoDefensivo;
            sharedNextPage("checklist/preguntas-checklist", objData, history);
        } catch (err) {
            console.log("editarElementoSelected err");
            console.log(err);
        }
    };

    const goToFormularioChecklist = (data) => {
        try {
            console.log('goToFormularioChecklist');
            console.log(data);
            if (data.acciones !== undefined) {
                delete data.acciones;
            }
            let objData = {};
            objData.id = data.id;
            sharedNextPage("checklist/formulario-checklist", objData, history);
        } catch (err) {
            console.log("goToFormularioChecklist err");
            console.log(err);
        }
    }

    const cambiarEstado = async (data) => {
        try {
            console.log("cambiarEstado");
            console.log(data);
            setElementoSelected(data);
            setModalConfirmarDeshabilitarVisible(true);
        } catch (err) {
            console.log("cambiarEstado err");
            console.log(err);
        }
    };

    const handleNuevoClick = () => {
        setElemento({});
        setModalCrearChecklistVisible(true);
    }

    const handleAtrasClick = () => {
        history.goBack();
    }

    const validar = () => {
        try {
            // console.log('validar');
            // console.log(elemento);
            let error = "";
            if (elemento.nombre === undefined || elemento.nombre === null || String(elemento.nombre).trim().length === 0) {
                error = error + "Nombre es requerido \n";
            }
            if (elemento.nota === undefined || elemento.nota === null) {
                elemento.nota = "";
            }
            if (String(error).length > 0) {
                alert(error);
                return false;
            }
            return true;
        } catch (err) {
            return false;
        }
    }

    const crearChecklist = async (tipoChecklistSelected) => {
        try {
            // console.log('crearChecklist');
            const valid = validar();
            if (valid === false) {
                return;
            }
            elemento.idTipoChecklist = tipoChecklistSelected !== null ? tipoChecklistSelected.value : null;
            // elemento.idArea = areaSelected !== null ? areaSelected.value : null;
            // elemento.idCiudad = ciudadSelected !== null ? ciudadSelected.value : null;
            // elemento.idMarcaVehiculo = marcaVehiculoSelected !== null ? marcaVehiculoSelected.value : null;
            // elemento.idTipoVehiculo = tipoVehiculoSelected !== null ? tipoVehiculoSelected.value : null;
            // elemento.idTipoCarroceria = tipoCarroceriaSelected !== null ? tipoCarroceriaSelected.value : null;
            // elemento.idTipoCombustible = tipoCombustibleSelected !== null ? tipoCombustibleSelected.value : null;
            console.log(elemento);

            const creado = await crearNuevoChecklist(elemento);
            // console.log('creado');
            // console.log(creado);
            if (creado.error === false) {
                alert('Creado correctamente');
                setModalCrearChecklistVisible(false);
                let objData = {};
                objData.id = creado.id;
                sharedNextPage("checklist/preguntas-checklist", objData, history);
                // setCargandoPage(true);
                // iniciar();
            }
            if (creado.error === true) {
                alert('No se pudo crear, intente nuevamente.')
            }
        } catch (err) {
            console.log('crearChecklist err');
            console.log(err);
        }
    }

    const cancelarCrearChecklist = () => {
        setModalCrearChecklistVisible(false);
    }

    const handleInputChange = (event) => {
        // console.log('handleInputChange');
        const { name, value } = event.target;
        // console.log(name);
        // console.log(value);
        setElemento({ ...elemento, [name]: value });
    };

    const handleCancelModal = () => {
        setModalConfirmarDeshabilitarVisible(false);
    }

    const handleConfirmModal = async () => {
        setModalConfirmarDeshabilitarVisible(false);
        if (elementoSelected == null) {
            return;
        }
        let objEnviar = {};
        objEnviar.id = elementoSelected.id;
        if (elementoSelected.id > 0) {
            // console.log('crear correo');
            let deshabilitado = await deshabilitarChecklist(objEnviar);
            // console.log('creado');
            // console.log(creado);
            if (deshabilitado === true) {
                alert('Se deshabilito correctamente');
                reset();
            }
            if (deshabilitado === false) {
                alert('No se pudo deshabilitar');
            }
        }
    }

    const reset = async () => {
        setCargandoPage(true);
        iniciar();
    }

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (tieneAccesoAlModulo === false) {
                return sinAccesoCard();
            }
            return (
                <div>
                    <div className="pt-4 px-4 flex justify-between">
                        <div className="flex justify-center align-center">
                            <label
                                style={{
                                    paddingLeft: 10,
                                    fontWeight: 'bold',
                                }}
                            >
                                GESTIONAR MIS CHECKLIST
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_NUEVO}
                                onClick={handleNuevoClick}
                                style={{
                                    display: accesoBotonNuevo,
                                }}
                            >
                                NUEVO
                            </button>
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleAtrasClick}
                            >
                                ATRAS
                            </button>
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleAtrasClick}
                            >
                                <Settings />
                            </button>
                        </div>
                    </div>
                    <Grid rows={listaElementos} headers={headers} />
                    <ModalConfirmar
                        isOpen={modalConfirmarDeshabilitarVisible}
                        onCancel={handleCancelModal}
                        onConfirm={handleConfirmModal}
                        message="¿Estás de deshabilitar Checklist?"
                    />
                    <ModalCrearChecklist
                        isOpen={modalCrearChecklistVisible}
                        onCancel={cancelarCrearChecklist}
                        onConfirm={crearChecklist}
                        elemento={elemento}
                        handleInputChange={handleInputChange}
                    />
                </div>
            );
        } catch (err) {
            console.log('render err dashboard');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}